import { DEFAULT_LANGUAGE as LANG } from './feature-flags';
/**
 * ************************************************
 * Time & Language Property
 * ************************************************
 */
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import 'dayjs/locale/en';

/**
 * ************************************************
 * Flag to to set Bride to BOY first
 * ************************************************
 */
export const IS_BOY_FIRST = true;

/**
 * ************************************************
 * Bride & Groom Info
 * ************************************************
 */
export const GIRL_NAME = 'Theresia Avilla Cindy Chika, S.Psi';
export const GIRL_NAME_SHORT = 'Cindy';
export const GIRL_FATHER_NAME = `Rudy Kurniawan`;
export const GIRL_MOTHER_NAME = `Lisa Chandra`;
export const GIRL_PARENT_NAME = 
  LANG === 'en'
    ? `The First Daughter of Mr. ${GIRL_FATHER_NAME} <br />and Mrs. ${GIRL_MOTHER_NAME}`
    : `Putri dari Bapak ${GIRL_FATHER_NAME} & Ibu ${GIRL_MOTHER_NAME}`
;
// -> boy section
export const BOY_NAME = 'Daniel Anggakusuma, B. Com';
export const BOY_NAME_SHORT = 'Daniel';
export const BOY_FATHER_NAME = `Ir. Angrijanto`;
export const BOY_MOTHER_NAME = `Ledi Supianti`;
export const BOY_PARENT_NAME = 
  LANG === 'en'
    ? `The First Son of Mr. ${BOY_FATHER_NAME} <br />and Mrs. ${BOY_MOTHER_NAME}`
    : `Putra dari Bapak ${BOY_FATHER_NAME} & Ibu ${BOY_MOTHER_NAME}`
;
// -> bride section
export const THE_BRIDE = IS_BOY_FIRST
  ? `${BOY_NAME_SHORT} & ${GIRL_NAME_SHORT}`
  : `${GIRL_NAME_SHORT} & ${BOY_NAME_SHORT}`;

/**
 * ************************************************
 * Instagram Profile Account
 * @important please put instagram id without `@`
 * ************************************************
 */
export const IG_BOY = 'danielanggakusuma';
export const IG_GIRL = 'cindychka';
export const IG_FILTER = `https://www.instagram.com/ar/2421859714664738/`;

/**
 * ************************************************
 * SEO Requirement
 * @important - Don't forget to update SEO IMAGE
 * ************************************************
 */
export const SEO_IMAGE = `https://ik.imagekit.io/josantoso/daniel-cindy/seo.jpg?updatedAt=1701321577184`;
export const SEO_URL = 'https://invitato.net/';
export const SEO_TITLE = `The Wedding of ${THE_BRIDE} by Invitato`;
export const SEO_DESCRIPTION =
  LANG === 'en'
    ? `Together with joyful hearts, we re pleased to announce the beginning of this new chapter of our lives together. Please click the Website Invitation link on this message for more information about our wedding details.`
    : `Menjadi sebuah kebahagiaan bagi kami untuk mengumumkan awal dari babak baru kehidupan kami bersama. Silakan klik tautan situs Undangan Website di bawah untuk informasi lebih lanjut:`;

/**
 * ************************************************
 * Time requirement for Counting Down, and
 * Remind me generator
 * @important - please convert time to Epoch time by
 * using this link https://www.epochconverter.com/
 * ************************************************
 */
export const EPOCH_START_EVENT = 1706331600;
export const EPOCH_RESEPSI_START = 1706331600;
export const EPOCH_END_EVENT = 1706362200;

/**
 * ************************************************
 * DATE REQUIREMENT
 * @important - Format date YYYY-MM-DD
 * ************************************************
 */
export const DATE_AKAD = new Date('2024-01-27');
export const DATE_RESEPSI = DATE_AKAD;

export const WEDDING_AKAD_TIME = '12.00 WIB';
export const WEDDING_AKAD_LOC_NAME = 'Gereja St. Yakobus Kelapa Gading';
export const WEDDING_AKAD_LOC_ROAD = `TNI AL Kodamar, Jl. Pulo Bira Besar RT 15 RW 03, Kelapa Gading Barat, Jakarta Utara 14240`;
export const WEDDING_AKAD_DRESSCODE = ``;
export const WEDDING_AKAD_FULLDATE = dayjs(DATE_AKAD)
  .locale(LANG)
  .format('dddd, DD MMMM YYYY');

export const WEDDING_RESEPSI_TIME = '18.30 WIB';
export const WEDDING_RESEPSI_LOC_NAME = `Grand Hyatt Hotel Jakarta`;
export const WEDDING_RESEPSI_LOC_ROAD = `Jl. M.H. Thamrin RT 09 RW 05, Gondangdia, Menteng, Jakarta Pusat 10350`;
export const WEDDING_RESEPSI_DRESSCODE = `Glamour, Formal look`;
export const WEDDING_RESEPSI_FULLDATE = dayjs(DATE_RESEPSI)
  .locale(LANG)
  .format('dddd, DD MMMM YYYY');
export const WEDDING_RESEPSI_DATE = WEDDING_AKAD_FULLDATE;
// export const WEDDING_RESEPSI_DATE = dayjs(DATE_RESEPSI)
//   .locale(DEFAULT_LANGUAGE)
//   .format('DD • MM • YYYY');

/**
 * ********************************************************
 * Link Generator V2
 * @important - this info will be applied at link generator
 * ********************************************************
 */
export const HOSTNAME = 'https://danielcindy.com/';
export const BROADCAST_WEDDING_LOCATION = `Grand Hyatt Hotel Jakarta`;
export const BROADCAST_WEDDING_DAY = {
  id: dayjs(DATE_RESEPSI).locale('id').format('dddd, DD MMMM YYYY'),
  en: dayjs(DATE_RESEPSI).locale('en').format('dddd, DD MMMM YYYY'),
};

/**
 * ************************************************
 * Maps Location
 * ************************************************
 */
export const GOOGLE_MAPS_LINK = `https://maps.app.goo.gl/hNVPgAU7KJx1MSfYA`;
export const GOOGLE_MAPS_ADDRESS = `Address details can be seen on the website invitation`;

/**
 * ************************************************
 * Backsound Copyright
 * ************************************************
 */
export const SOUND_BY = `Endless Love - Lionel Richie (Piano Cover by Riyandi Kusuma)`;
export const SOUND_URL = 'https://youtu.be/K0KybecKZYg?si=N6-CaN0CTZkwCNgy';

/**
 * ************************************************
 *  Youtube Live requirement
 * ************************************************
 */
export const YOUTUBE_LINK = 'https://youtu.be/2Bmxzm0itV8?si=YOZqlP4rvAWVXHaq';
export const YOUTUBE_EMBED = 'https://www.youtube.com/embed/2Bmxzm0itV8?si=YOZqlP4rvAWVXHaq';
export const YOUTUBE_PREWEDDING_EMBED = `https://www.youtube.com/embed/YTUrwx5Wa18?si=vGPQJAexhiD7VNyk`;
export const YOUTUBE_PREWEDDING_LINK = `https://youtu.be/YTUrwx5Wa18?si=vGPQJAexhiD7VNyk`;

/**
 * ************************************************
 *  RSVP & Wishlish Section
 * ************************************************
 */
export const API_HOSTNAME = `https://script.google.com/macros/s/AKfycbzi2RRjF_tDzMZ9pJODAO4LXgaOpx3Wh5AWXmPlNvhcCKqfIvuQAWwe_NC2Xer1HY8/exec`;
export const API_TABLE_NAME = 'wishlist';
export const API_RSVP_TABLE = 'rsvp';
export const SHEET_GIFT_CONFIRMATION = 'gift_confirmation';

/**
 * ************************************************
 *  Invitato Link Requirement
 * ************************************************
 */
export const URL_INVITATO = 'https://invitato.net';
export const URL_IG_INVITATO = 'https://www.instagram.com/dindadipoyono/';
export const URL_WA_INVITATO = 'https://wa.me/+628112778189?text=Hi%20Kak,%20aku%20mau%20pesan%20undangan%20online%20nih!';